import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';

import List from '@mui/material/List';

import { withSubtheme } from '../../StarberryComponentsMui';
import Logo from './Logo';
import LogoSecondary from './Logo/LogoSecondary';
import DefineIconMenu from './iconMenuList/DefineIconMenu';
import MyaccountContent from './Content'
import AppBarComp from './AppBar'

import defaults from './SidebarDrawerDefaults';
import { featureRoutesMap } from "../../StarberryComponentsMui/features"
import AppFeaturesContext from "../../StarberryComponentsMui/AppFeaturesContext"

const MyAccountLayout = withSubtheme(props => {

    const {
        className,
        theme,
        pageTitle,
        pageLeftIcon,
        pageRightDrawer,
        pageRightCustomComponent,
        children,

        drawerMobileHidden,
        drawerMobile,
        drawerDesktopHidden,
        drawerDesktop,
        menus,
        menuListItem
    } = props

    const { appFeatures, allFeaturesEnabled } = React.useContext(AppFeaturesContext);

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerList = (
        <List classes={{root: "sidebarDrawer-menuList"}}>
            {menus
                .filter(menu => {
                    return menu?.menutype === 'external' || menu.link === '#' || menu.link === '/' || allFeaturesEnabled || appFeatures.find(
                        feature => featureRoutesMap[feature].test(menu.link),
                    );
                })
                .map((linkName) => (
                <DefineIconMenu
                    key={linkName.id || linkName.name.toString()}
                    id={linkName.id}
                    text={linkName.name.toString()}
                    iconName={linkName.icon.name}
                    url={linkName.link}
                    hide={linkName.hide}
                    newtab={linkName.newtab || false}
                    funcname={linkName?.funcName || null}
                    menuListItem={menuListItem}
                    classesMenuListItem="sidebarDrawer-menuListItem"
                    classesMenuListItemIcon="sidebarDrawer-menuListItemIcon"
                />
            ))}
        </List>
    );

    const drawer = (
        <div>
            <Logo />
            {drawerList}
        </div>
    );
    const drawerM = (
        <div>
            {/*theme.getProp("logoSecondary") ? <LogoSecondary /> : <Logo />*/}
            <Logo />
            {drawerList}
        </div>
    );

    return (
        <div className={className}>
            <div
                className="sidebarDrawer-drawerCommon-root"
            >
                <CssBaseline />
                <AppBarComp
                    pageTitle={pageTitle}
                    pageLeftIcon={pageLeftIcon}
                    pageRightDrawer={pageRightDrawer}
                    pageRightCustomComponent={pageRightCustomComponent}
                    handleDrawerToggle={handleDrawerToggle}
                />
                {drawerDesktop.anchor === "left" &&
                    <>
                        <nav className="sidebarDrawer-drawerCommon-drawer" aria-label="Myaccount menu">
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Hidden {...drawerDesktopHidden}>
                                <Drawer
                                    {...drawerDesktop}
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    classes={{
                                        paper: "sidebarDrawer-drawerCommon-drawerPaper",
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                        className: className
                                    }}
                                >
                                    {drawerM}
                                </Drawer>
                            </Hidden>
                            <Hidden {...drawerMobileHidden}>
                                <Drawer
                                    {...drawerMobile}
                                    classes={{
                                        paper: "sidebarDrawer-drawerCommon-drawerPaper",
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Hidden>
                        </nav>
                        <MyaccountContent>
                            {children}
                        </MyaccountContent>
                    </>
                }
                {/* for the right positioned drawer we need to swap the html position of main content and sidebar */}
                {drawerDesktop.anchor === "right" &&
                    <>
                        <MyaccountContent>
                            {children}
                        </MyaccountContent>
                        <nav className="sidebarDrawer-drawerCommon-drawer" aria-label="Myaccount menu">
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Hidden {...drawerDesktopHidden}>
                                <Drawer
                                    {...drawerDesktop}
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    classes={{
                                        paper: "sidebarDrawer-drawerCommon-drawerPaper",
                                    }}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                        className: className
                                    }}
                                >
                                    {drawerM}
                                </Drawer>
                            </Hidden>
                            <Hidden {...drawerMobileHidden}>
                                <Drawer
                                    {...drawerMobile}
                                    classes={{
                                        paper: "sidebarDrawer-drawerCommon-drawerPaper",
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Hidden>
                        </nav>
                    </>
                }
            </div>
        </div>
    );
}, 'sidebarDrawer', defaults)

export default React.memo(MyAccountLayout);